import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'remote-user-portal/api';

import EmsInstruction from './EmsInstruction';
import VpnConfig from './VpnConfig';

library.add(fab);

const SiteContent = ({ userName, site }) => {
  const user = useSelector(state => state.account.user);
  const license = _get(user, 'tenant.company.license');
  const isEmsEntitled = _get(license, 'security_entitlement.site.ems_enforce');
  const [links, setLinks] = useState();

  const getDownloadLinks = useCallback(() => {
    api.getDownloadLinks().then(data => {
      return setLinks(data);
    });
  }, []);

  useEffect(() => {
    getDownloadLinks();
  }, [getDownloadLinks]);

  return (
    <div>
      <section>
        {isEmsEntitled ? (
          <div style={{ marginLeft: 24 }}>
            <EmsInstruction site={site} links={_get(links, 'forticlient_ems', [])} />
          </div>
        ) : (
          <div>
            <VpnConfig site={site} userName={userName} links={_get(links, 'forticlient', [])} />
          </div>
        )}
      </section>
    </div>
  );
};

SiteContent.propTypes = {
  userName: PropTypes.string,
  site: PropTypes.object,
};

export default SiteContent;

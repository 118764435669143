import './ResetPassword.module.less';

import {
  cacheLoginQuery,
  resetPassword,
  verifyToken,
} from '@remote-user-portal/redux/Account/account.actions';
import { ERROR_404_PATH, LOGIN_PATH } from '@remote-user-portal/shared/constants';
import { Button, Result, Typography } from 'antd';
import _get from 'lodash/get';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logout } from 'remote-user-portal/redux/Auth/auth.actions';

import ResetPasswordForm from './ResetPasswordForm';

const jwtDecode = require('jwt-decode');
const { Title } = Typography;

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const loginQuery = useSelector(state => state.account.loginQuery);
  const [submitted, setSubmitted] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [account, setAccount] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    // if authenticated, logout first
    if (isAuthenticated) {
      dispatch(logout());
    }
  }, []);

  // verify the validity of token
  const verify = useCallback(token => {
    dispatch(verifyToken('reset_password', token))
      .then(res => {
        const { code, error } = res;
        let message = _get(error, 'message', '');
        switch (code) {
          case 200:
            let account = jwtDecode(token);
            account = account && account.user;
            setAccount(account);
            break;
          case 400:
          case 403:
          case 404:
            setErrorMessage(message);
            break;
          default:
        }
      })
      .catch(err => {
        console.log('Token verification error:', err);
        const message = 'Something went wrong...';
        setErrorMessage(message);
      });
  }, []);

  useEffect(() => {
    const queryParams = location.search;
    const { token } = queryString.parse(queryParams);

    if (!token) {
      history.push(ERROR_404_PATH);
      return;
    }

    verify(token);
  }, []);

  const handleResetPassword = useCallback(
    payload => {
      const queryParams = location.search;
      const { token } = queryString.parse(queryParams);
      const { password } = payload;
      setSubmitted(true);
      dispatch(resetPassword(account.id, { password }, token))
        .then(() => {
          const { tenant_id, site_id } = account;
          const loginQuery = queryString.stringify({ tenant_id, site_id });
          dispatch(cacheLoginQuery(`?${loginQuery}`));
          setSucceeded(true);
        })
        .finally(() => setSubmitted(false));
    },
    [account]
  );

  return (
    <div styleName="reset-password" className="flex-col main-center cross-center">
      {succeeded ? (
        <Result
          status="success"
          title="Reset Password Succeeded!"
          subTitle={
            <p>
              Your password has been reset. Please{' '}
              <Link to={LOGIN_PATH + loginQuery}>click here to log in</Link>.
            </p>
          }
        />
      ) : errorMessage ? (
        <Result
          status="warning"
          title={errorMessage}
          style={{ width: '800px' }}
          extra={
            loginQuery && (
              <Button
                size="small"
                type="primary"
                key="login"
                onClick={history.push(LOGIN_PATH + loginQuery)}
              >
                Login
              </Button>
            )
          }
        />
      ) : (
        <div styleName="form">
          <Title level={4}>Reset Password</Title>
          <ResetPasswordForm submitted={submitted} onSubmit={handleResetPassword} />
        </div>
      )}
    </div>
  );
};

export default ResetPassword;

import './ChangePassword.module.less';

import { message, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from 'remote-user-portal/redux/Account/account.actions';

import ChangePasswordForm from './ChangePasswordForm';
const DEFAULT_MODAL_CONFIG = {
  centered: true,
  destroyOnClose: true,
  maskClosable: false,
};

const ChangePasswordModal = forwardRef(({ ...modalOptions }, ref) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.account.user);
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    showModal: () => {
      setVisible(true);
    },
  }));

  const handleChangePassword = useCallback(
    payload => {
      setSubmitting(true);
      dispatch(changePassword(user.id, payload))
        .then(_ => {
          message.success(`Password has been successfully updated.`);
          setVisible(false);
        })
        .finally(() => setSubmitting(false));
    },
    [dispatch, user.id]
  );

  return (
    <Modal
      {...DEFAULT_MODAL_CONFIG}
      title="Change Password"
      visible={visible}
      confirmLoading={submitting}
      okText="Save"
      onCancel={() => setVisible(false)}
      footer={
        <div styleName="footer" className="flex-row main-center cross-center">
          <button
            className="primary"
            disabled={submitting}
            onClick={() => formRef.current && formRef.current.submit()}
          >
            Save
          </button>
          <button
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      }
      {...modalOptions}
    >
      <ChangePasswordForm ref={formRef} onSubmit={handleChangePassword} />
    </Modal>
  );
});

ChangePasswordModal.displayName = 'ChangePasswordModal';
ChangePasswordModal.propTypes = {};

export default ChangePasswordModal;

import './Activation.module.less';

import { Form, Input } from 'antd';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback } from 'react';
import { passwordRules } from 'remote-user-portal/shared/utils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const ActivationForm = forwardRef(({ account, submitted, onSubmit }, ref) => {
  const [form] = Form.useForm();
  form.setFieldsValue({
    user_name: _get(account, 'user_name'),
    email: _get(account, 'email'),
  });
  const handleSubmit = useCallback(
    values => {
      onSubmit(values);
    },
    [account, onSubmit]
  );

  return (
    <Form
      ref={ref}
      form={form}
      onFinish={handleSubmit}
      style={{ width: '100%' }}
      {...formItemLayout}
    >
      <Form.Item
        name={'user_name'}
        label="Username"
        rules={[{ required: true, message: 'User Name is Required' }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name={'email'}
        label="Email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="password" label="Password" validateTrigger="onBlur" rules={passwordRules}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <div className="flex-row main-center" styleName="footer">
        <button className="primary" type="submit" data-test="activate-btn" disabled={submitted}>
          Activate
        </button>
      </div>
    </Form>
  );
});

ActivationForm.displayName = 'ActivationForm';
ActivationForm.propTypes = {
  account: PropTypes.object,
  submitted: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default ActivationForm;

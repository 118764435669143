import './ForgotPassword.module.less';

import { requestPasswordReset } from '@remote-user-portal/redux/Account/account.actions';
import { LOGIN_PATH } from '@remote-user-portal/shared/constants';
import { Result, Typography } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ForgotPasswordForm from './ForgotPasswordForm';
const { Title, Paragraph } = Typography;

const ForgotPassword = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const queryParams = location.search;
    const { account } = queryString.parse(queryParams);
    setAccount(account);
  }, [history, location.search]);

  const handleReset = useCallback(
    payload => {
      setSubmitted(true);

      dispatch(
        requestPasswordReset({
          url: `${window.location.protocol}//${window.location.host}/userportal`,
          ...payload,
        })
      )
        .then(() => {
          setSucceeded(true);
        })
        .catch(err => console.log({ err }))
        .finally(() => setSubmitted(false));
    },
    [dispatch]
  );

  return (
    <div styleName="forgot-password" className="flex-col main-center cross-center">
      {succeeded ? (
        <Result
          status="success"
          title="Password Reset Email Sent!"
          subTitle="An email has been sent to your email address. Please follow the directions in the email to reset your password."
          style={{ backgroundColor: 'white', borderRadius: 3, maxWidth: '90vw' }}
        />
      ) : (
        <div styleName="form" className="flex-col">
          <Title level={4}>Forgot Password?</Title>
          <Paragraph type="secondary">
            Please enter the Primary Account/ID, the Username and Email you use for your account.
            We&apos;ll send you instructions to reset your password.
          </Paragraph>
          <div className="flex-col main-center cross-center flex-item">
            <ForgotPasswordForm
              account={account}
              submitted={submitted}
              onSubmit={handleReset}
              onCancel={() =>
                history.push({
                  pathname: LOGIN_PATH,
                  search: account && `?tenant_id=${account}`,
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;

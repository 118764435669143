import { Result } from 'antd';
import React from 'react';

const Page404 = () => (
  <div style={{ height: '100%' }} className="flex-col main-center cross-center">
    <Result
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      style={{ maxWidth: '90vw' }}
    />
  </div>
);

export default Page404;

import './Header.module.less';

import { lightbulbIcon, moonIcon } from '@neutrino/fa-icons/outlined';
import { caretDownIcon, userCircleIcon } from '@neutrino/fa-icons/solid';
import { Dropdown, Layout, Menu } from 'antd';
import saseLogo from 'assets/images/sase_logo.svg';
import NuIcon from 'components/NuIcon';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeTheme } from 'remote-user-portal/redux/Account/account.actions';
import { logout } from 'remote-user-portal/redux/Auth/auth.actions';
import { LOGIN_PATH } from 'remote-user-portal/shared/constants';

import ChangePasswordModal from './ChangePasswordModal';

const Header = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const changePasswordModalRef = useRef();
  const user = useSelector(state => state.account.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const themeMode = useSelector(state => state.account.theme);
  const userName = _get(user, 'user_name', '');

  const handleChangeTheme = useCallback(() => {
    document.activeElement.blur();
    const theme = themeMode === 'dark' ? 'light' : 'dark';
    dispatch(changeTheme(theme));
  }, [themeMode, dispatch]);

  const showChangePasswordModal = useCallback(e => {
    e.preventDefault();
    changePasswordModalRef.current.showModal();
  }, []);

  const logoutPortal = useCallback(
    e => {
      e.preventDefault();
      dispatch(logout());
      history.push(LOGIN_PATH);
    },
    [dispatch, history]
  );

  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={showChangePasswordModal}>Change Password</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={logoutPortal}>Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Header className="flex-row space-between" styleName="header">
      <section styleName="logo" onClick={() => history.push('/')}>
        <img src={saseLogo} alt="logo" />
        <strong>{en.SYSTEM.PRODUCT_NAME}</strong>
      </section>

      <div className="flex-row main-end cross-center" styleName="actions">
        <button className="bare" onClick={handleChangeTheme}>
          <NuIcon icon={themeMode === 'dark' ? lightbulbIcon : moonIcon} />
        </button>
        {isAuthenticated && (
          <Dropdown overlay={menu} trigger={['click']} overlayClassName="nu-pop-up-menu-content">
            <span styleName="dropdown-menu">
              <NuIcon icon={userCircleIcon} style={{ width: 24, height: 24, color: '#fff' }} />
              <span style={{ marginLeft: '.3em' }}>{userName} </span>
              <NuIcon icon={caretDownIcon} />
            </span>
          </Dropdown>
        )}
        <ChangePasswordModal ref={changePasswordModalRef} />
      </div>
    </Layout.Header>
  );
};

Header.propTypes = {};

export default Header;

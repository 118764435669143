import './ChangePassword.module.less';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { passwordRules } from 'remote-user-portal/shared/utils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ChangePasswordForm = forwardRef(({ onSubmit }, ref) => {
  return (
    <div styleName="form">
      <Form ref={ref} {...formItemLayout} onFinish={onSubmit}>
        <Form.Item
          name="current_password"
          label="Current Password"
          rules={[
            {
              required: true,
              message: 'Please input your current password!',
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="password"
          label="New Password"
          rules={passwordRules}
          validateTrigger="onBlur"
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            {
              min: 8,
              message: 'Password must be at least 8 characters long.',
            },
            {
              max: 64,
              message: 'Password must be within 64 characters long.',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two passwords that you entered do not match!');
              },
            }),
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
});

ChangePasswordForm.displayName = 'ChangePasswordForm';
ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ChangePasswordForm;

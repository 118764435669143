import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space, Typography } from 'antd';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

const { Paragraph, Text } = Typography;

const EmsInstruction = ({ site, links }) => {
  return (
    <div>
      <h3>Connect to {en.SYSTEM.PRODUCT_NAME}</h3>
      <div style={{ width: 760, maxWidth: '100%' }}>
        <section style={{ marginTop: 24 }}>
          <Paragraph>
            <strong>Step 1: Download FortiClient Application</strong>
          </Paragraph>
          <Space wrap>
            {links.map(({ label, key, icon, link }) => (
              <button className="secondary" key={key} onClick={() => window.open(link)}>
                <FontAwesomeIcon icon={icon} size="lg" />
                &nbsp; {label}
              </button>
            ))}
          </Space>
        </section>

        <section style={{ marginTop: 24 }}>
          <Paragraph>
            <strong>Step 2: Enroll FortiSASE Endpoint Protection</strong>
          </Paragraph>
          <Paragraph>
            Invitation Code: <Text copyable>{_get(site, 'invitation_code', '')}</Text>
          </Paragraph>
          <Paragraph>
            After installing FortiClient, please navigate to the “FABRIC TELEMETRY” menu item,
            select “Join FortiClient Cloud”, enter the invitation code above and click “Connect”.
          </Paragraph>
        </section>
      </div>
    </div>
  );
};

EmsInstruction.propTypes = {
  site: PropTypes.object,
  links: PropTypes.array,
};

export default EmsInstruction;

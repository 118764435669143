import './ForgotPassword.module.less';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect } from 'react';

const { useForm } = Form;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ForgotPasswordForm = forwardRef(({ onSubmit, submitted, onCancel, account }, ref) => {
  const [form] = useForm();
  useEffect(() => {
    if (account) {
      form.setFieldValue('master_account', account);
    }
  }, [account, form]);

  const handleSubmit = useCallback(
    values => {
      onSubmit({
        ...values,
        email: values.user_name,
      });
    },
    [onSubmit]
  );

  return (
    <Form
      form={form}
      ref={ref}
      style={{ width: '100%' }}
      onFinish={handleSubmit}
      {...formItemLayout}
    >
      <Form.Item
        label="Primary Account/ID"
        name="master_account"
        rules={[{ required: true, message: 'Please input your Primary Account!' }]}
      >
        <Input data-test="primary-account-input" />
      </Form.Item>

      <Form.Item
        label="Username"
        name="user_name"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input data-test="username-input" />
      </Form.Item>

      <div className="flex-row main-center" styleName="footer">
        <button className="primary" type="submit" data-test="reset-btn" disabled={submitted}>
          Reset Password
        </button>
        <button type="button" data-test="back-btn" onClick={onCancel}>
          Back
        </button>
      </div>
    </Form>
  );
});

ForgotPasswordForm.displayName = 'ForgotPasswordForm';
ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  account: PropTypes.string,
};

export default ForgotPasswordForm;

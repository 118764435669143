import './Activation.module.less';

import { activateRemoteUser, verifyToken } from '@remote-user-portal/redux/Account/account.actions';
import { ERROR_404_PATH, LOGIN_PATH } from '@remote-user-portal/shared/constants';
import { Result, Typography } from 'antd';
import _get from 'lodash/get';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logout } from 'remote-user-portal/redux/Auth/auth.actions';

import ActivationForm from './ActivationForm';

const jwtDecode = require('jwt-decode');
const { Title } = Typography;

const Activation = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [submitted, setSubmitted] = useState(false);
  const [activated, setActivated] = useState(false);
  const [account, setAccount] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    // if authenticated, logout first
    if (isAuthenticated) {
      dispatch(logout());
    }
  }, [dispatch, isAuthenticated]);

  // verify the validity of token
  const verify = useCallback(
    token => {
      dispatch(verifyToken('register', token))
        .then(res => {
          const { code, error } = res;
          let message = _get(error, 'message', '');

          switch (code) {
            case 200:
              let account = jwtDecode(token);
              account = account && account.user;
              setAccount(account);
              break;
            case 400:
            case 403:
            case 404:
              setErrorMessage(message);
              break;
            default:
          }
        })
        .catch(err => {
          console.log('Token verification error:', err);
          const message = 'Something went wrong...';
          setErrorMessage(message);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    const queryParams = location.search;
    const { token } = queryString.parse(queryParams);

    if (!token) {
      history.push(ERROR_404_PATH);
      return;
    }
    verify(token);
  }, [history, location.search, verify]);

  const handleActivation = useCallback(
    data => {
      setSubmitted(true);
      const { name, password } = data;
      const queryParams = location.search;
      const { token } = queryString.parse(queryParams);

      dispatch(activateRemoteUser(account?.id, { password, name }, token))
        .then(() => {
          setActivated(true);
        })
        .finally(() => setSubmitted(false));
    },
    [account?.id, dispatch, location.search]
  );

  const tenant_id = account && account.tenant_id;
  const loginURL = !!tenant_id
    ? `${LOGIN_PATH}?${queryString.stringify({ tenant_id })}`
    : LOGIN_PATH;

  return (
    <div styleName="activation" className="flex-col main-center cross-center">
      {activated ? (
        <Result
          status="success"
          title="Your account is now active!"
          subTitle={
            <p>
              You account has been activated. You can now <Link to={loginURL}>log in</Link> to the
              portal using your <strong>Username</strong> and <strong>Password</strong>.
            </p>
          }
        />
      ) : errorMessage ? (
        <Result
          status="warning"
          title={errorMessage}
          style={{
            width: '800px',
          }}
        />
      ) : (
        <div styleName="form">
          <Title level={4}>Account Activation </Title>
          <ActivationForm
            account={account}
            activated={activated}
            submitted={submitted}
            onSubmit={handleActivation}
          />
        </div>
      )}
    </div>
  );
};

export default Activation;

import '../index.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from 'serviceWorker';

import App from './App';

// set Sentry DSN based on the current env
const env = window?.location?.hostname?.split('.')?.[1];
let sentryDSN = '';

// the DSN is defined in the top-level pom.xml file
// and be referenced in project-level pom.xml and Dockerfile
switch (env) {
  case 'prod':
    sentryDSN = process.env.REACT_APP_SENTRY_DSN_PROD_REMOTE_USER_PORTAL;
    break;
  case 'stage':
    sentryDSN = process.env.REACT_APP_SENTRY_DSN_STAGE_REMOTE_USER_PORTAL;
    break;
  case 'dev':
    sentryDSN = process.env.REACT_APP_SENTRY_DSN_DEV_REMOTE_USER_PORTAL;
    break;
  case 'feature':
    sentryDSN = process.env.REACT_APP_SENTRY_DSN_FEATURE_REMOTE_USER_PORTAL;
    break;
  default:
}

sentryDSN &&
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

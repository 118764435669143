import jwtDecode from 'jwt-decode';
import _get from 'lodash/get';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import Page404 from 'remote-user-portal/core/Page404';
import Activation from 'remote-user-portal/pages/Activation';
import ForgotPassword from 'remote-user-portal/pages/ForgotPassword';
import Login from 'remote-user-portal/pages/Login';
import RemoteUserProfile from 'remote-user-portal/pages/Profile';
import ResetPassword from 'remote-user-portal/pages/ResetPassword';
import { logout } from 'remote-user-portal/redux/Auth/auth.actions';
import {
  ACTIVATION_PATH,
  ERROR_404_PATH,
  FORGOT_PWD_PATH,
  LOGIN_PATH,
  RESET_PWD_PATH,
  ROOT_PATH,
} from 'remote-user-portal/shared/constants';
import { ACCESS_TOKEN_KEY } from 'remote-user-portal/shared/constants';

export const RouteConfig = [
  {
    name: 'profile',
    path: `${ROOT_PATH}/profile`,
    exact: true,
    component: RemoteUserProfile,
    getBreadcrumbName: params => 'Profile',
  },
];

export const HOME_PATH = RouteConfig.find(({ name }) => name === 'profile').path;

export function getRoute(routeName) {
  return RouteConfig.find(route => route.name === routeName);
}

export const getPath = (routeName, params) => {
  const route = RouteConfig.find(r => r.name === routeName);
  if (route) {
    const path = generatePath(route.path, params);
    return path;
  }

  return '#';
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!sessionStorage.getItem(ACCESS_TOKEN_KEY);
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_PATH,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);

  const checkTokenExpiry = useCallback(() => {
    if (accessToken) {
      try {
        const decodeObj = jwtDecode(accessToken);
        const expiry = _get(decodeObj, 'exp', {});
        if (Date.now() > expiry * 1000) {
          dispatch(logout());
          if (location.pathname !== LOGIN_PATH) {
            history.push(LOGIN_PATH);
          }
        }
      } catch (error) {
        console.error('parse token error');
      }
    }
  }, [accessToken, dispatch, history, location.pathname]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={props => {
          checkTokenExpiry();
          const isAuthenticated = !!sessionStorage.getItem(ACCESS_TOKEN_KEY);
          return isAuthenticated ? (
            <Redirect to={ROOT_PATH} />
          ) : (
            <Redirect
              to={{
                pathname: LOGIN_PATH,
                state: { from: props.location },
              }}
            />
          );
        }}
      />

      {RouteConfig.filter(item => !!item.path && !!item.component).map(route => {
        switch (window.location.pathname) {
          case ACTIVATION_PATH:
          case RESET_PWD_PATH:
          case FORGOT_PWD_PATH:
            return (
              <PrivateRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          default:
            checkTokenExpiry();
            return (
              <PrivateRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
        }
      })}
      <Route path={LOGIN_PATH} component={Login} />
      <Route path={ACTIVATION_PATH} component={Activation} />
      <Route path={FORGOT_PWD_PATH} exact={true} component={ForgotPassword} />
      <Route path={RESET_PWD_PATH} exact={true} component={ResetPassword} />
      <Route path={ERROR_404_PATH} exact={true} component={Page404} />
      <Redirect to={HOME_PATH} />
    </Switch>
  );
};

export default Routes;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space, Spin, Typography } from 'antd';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SiteDetail from './SiteDetail';
const { Title, Text, Paragraph } = Typography;

const VpnConfig = ({ site, userName, links }) => {
  const [dataURL, setDataURL] = useState();

  const sslDomain = useMemo(() => (site ? `https://${site.ssl_gateway}` : ''), [site]);
  const generateQRCode = useCallback(() => {
    const content = `${sslDomain} 443 ${site.name} ${userName}`;
    QRCode.toDataURL(content, (error, url) => {
      if (error) console.error(error);
      console.log('success!', url);
      setDataURL(url);
    });
  }, [site.name, sslDomain, userName]);

  useEffect(() => {
    generateQRCode();
  }, [generateQRCode, userName]);

  return (
    <div style={{ position: 'relative', width: 480, marginLeft: 24 }}>
      {!QRCode ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <div>
          <h3>Connect to {en.SYSTEM.PRODUCT_NAME} via FortiClient Application</h3>
          <section style={{ marginTop: 24 }}>
            <Paragraph>
              <strong>Step 1: Download FortiClient Application for your Mobile device.</strong>
            </Paragraph>
            <Space wrap>
              {links.map(({ label, key, icon, link }) => (
                <button className="secondary" key={key} onClick={() => window.open(link)}>
                  <FontAwesomeIcon icon={icon} size="lg" />
                  &nbsp; {label}
                </button>
              ))}
            </Space>
          </section>

          <section style={{ marginTop: 24 }}>
            <Paragraph>
              <strong>Step 2: Open the FortiClient App and Scan the QR code to connect VPN.</strong>
            </Paragraph>
            <div className="flex-row main-center">
              <img
                alt="QR Code"
                src={dataURL}
                style={{ marginBottom: 20, width: 160, height: 160 }}
              ></img>
            </div>
            <Paragraph>
              OR <strong strong>click the button below to launch the APP and connect.</strong>
            </Paragraph>
            <button
              className="primary"
              onClick={() =>
                window.open(
                  `fabricagent://cloudvpn?host=${site.ssl_gateway}&name=${site.name}&port=443&user=${userName}`
                )
              }
            >
              Connect VPN
            </button>
          </section>
          <section style={{ marginTop: 32 }}>
            <div className="flex-row space-between"></div>
            <SiteDetail site={site}></SiteDetail>
          </section>
        </div>
      )}
    </div>
  );
};

VpnConfig.propTypes = {
  site: PropTypes.object,
  userName: PropTypes.string,
  links: PropTypes.array,
};

export default VpnConfig;

import { Layout } from 'antd';
import _get from 'lodash/get';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreakpoint } from 'redux/GlobalSetting/globalSetting.actions';
import { getBreakpoint } from 'utils/util';

import Header from './Header';
import Routes from './Routes';

const { Content } = Layout;

const AppLayout = () => {
  const dispatch = useDispatch();
  const breakpoint = useSelector(state => state.globalSetting.breakpoint);

  // - get breakpoint when window resize
  // - update to globalSettings if breakpoint changed
  const handleResize = useCallback(() => {
    const current = getBreakpoint();
    if (current !== breakpoint) {
      dispatch(setBreakpoint(current));
    }
  }, []);

  useEffect(() => {
    const breakpoint = getBreakpoint();
    dispatch(setBreakpoint(breakpoint));
    // attach resize handler to resize event
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Layout style={{ height: '100%' }}>
      <Header />
      <Content className="main-content flex-col flex-item">
        <Routes />
      </Content>
    </Layout>
  );
};

export default AppLayout;

import './Profile.module.less';

import { Descriptions, Divider, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'remote-user-portal/api';

import SiteContent from './SiteContent';

const { TabPane } = Tabs;

const Profile = () => {
  const user = useSelector(state => state.account.user);
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    api
      .getRemoteUserSites(user.id)
      .then(({ data }) => {
        setSites(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="flex-col flex-item" styleName="profile">
      <section style={{ marginLeft: 24 }}>
        <h3>Profile</h3>
        <Descriptions column={1}>
          <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
          <Descriptions.Item label="Username">{user.user_name}</Descriptions.Item>
        </Descriptions>
      </section>
      <Divider />
      <section style={{ position: 'relative', minHeight: 260 }}>
        {loading ? (
          <Spin spinning={loading} className="center spinner" />
        ) : sites.length === 1 ? (
          <SiteContent site={sites[0]} userName={user.user_name} />
        ) : (
          <Tabs defaultActiveKey="1" tabPosition="top">
            {sites.map((site, index) => (
              <TabPane tab={`${site.name}`} key={index}>
                <SiteContent site={site} userName={user.user_name} />
              </TabPane>
            ))}
          </Tabs>
        )}
      </section>
    </div>
  );
};

export default Profile;

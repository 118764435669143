import './theme/common.less';
import '@neutrino/theming/global-style.css';

import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import history from '@/history';

import AppLayout from './core/AppLayout';
import { updateTheme } from './shared/themingManager';
import store from './store';

function App() {
  updateTheme();

  return (
    <Provider store={store}>
      <div id="App">
        <Router history={history}>
          <AppLayout />
        </Router>
      </div>
    </Provider>
  );
}

export default App;

import { Descriptions, Spin, Typography } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

const { Paragraph } = Typography;

const SiteDetail = ({ site }) => {
  return (
    <div style={{ position: 'relative' }}>
      {_isEmpty(site) ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <>
          <Descriptions column={1}>
            <Descriptions.Item label="Site Name">{site.name}</Descriptions.Item>
            <Descriptions.Item label="Descriptions">{site.description}</Descriptions.Item>
            <Descriptions.Item label="FOS Region">{site.region_alias}</Descriptions.Item>
            <Descriptions.Item label="FOS POP">{site.pop_alias}</Descriptions.Item>
            <Descriptions.Item label="SSL VPN">
              <Paragraph copyable>{`https://${site.ssl_gateway}`}</Paragraph>
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
};
SiteDetail.propTypes = {
  site: PropTypes.object,
};

export default SiteDetail;

import './Login.module.less';

import { FORGOT_PWD_PATH } from '@remote-user-portal/shared/constants';
import { Form, Input } from 'antd';
import saseLogo from 'assets/images/sase_logo.svg';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { HOME_PATH } from 'remote-user-portal/core/Routes';
import { login } from 'remote-user-portal/redux/Account/account.actions';
import { ROOT_PATH } from 'remote-user-portal/shared/constants';

const { useForm } = Form;

const Login = () => {
  const [form] = useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [account, setAccount] = useState(null);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    // if already authenticated, route to the home page
    if (isAuthenticated) {
      history.push(ROOT_PATH);
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    const queryParams = location.search;
    const { tenant_id } = queryString.parse(queryParams);
    setAccount(tenant_id);
  }, [location.search]);

  useEffect(() => {
    if (account) {
      form.setFieldValue('master_account', account);
    }
  });

  const handleLogin = useCallback(
    data => {
      setSubmitted(true);
      const { master_account, user_name, password } = data;
      dispatch(login({ master_account, user_name, password: password }))
        .then(() => {
          setSubmitted(false);
          history.push(HOME_PATH);
        })
        .catch(err => setSubmitted(false));
    },
    [dispatch, history]
  );

  return (
    <div styleName="login" className="flex-col main-center cross-center">
      <div styleName="panel" className="flex-col">
        <div styleName="header">
          <img src={saseLogo} alt="FortiSASE logo" />
        </div>

        <div styleName="body">
          <div styleName="content" className=" flex-item flex-col main-center cross-center">
            <div style={{ marginTop: '.5em', width: '100%' }}>
              <Form
                form={form}
                onFinish={values => {
                  handleLogin(values);
                }}
                onValuesChange={changedValues => {
                  if (changedValues.master_account) {
                    setAccount(changedValues.master_account);
                  }
                }}
                disabled={submitted}
                style={{}}
              >
                <Form.Item
                  name="master_account"
                  rules={[{ required: true, message: 'Please input your Primary Account!' }]}
                >
                  <Input placeholder="Primary Account/ID" data-test="primary-account-input" />
                </Form.Item>

                <Form.Item
                  name="user_name"
                  rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                  <Input placeholder="Username" data-test="username-input" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                  <Input type="password" placeholder="Password" data-test="password-input" />
                </Form.Item>

                <Form.Item noStyle>
                  <a
                    onClick={() =>
                      history.push({
                        pathname: FORGOT_PWD_PATH,
                        ...(account && { search: `?account=${account}` }),
                      })
                    }
                    data-test="forgot-password-link"
                  >
                    Forgot password?
                  </a>
                  <br />
                  <button
                    className="primary block"
                    type="submit"
                    disabled={submitted}
                    data-test="login-btn"
                    style={{ marginTop: '1em' }}
                  >
                    Log in
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

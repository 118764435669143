import './ResetPassword.module.less';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback } from 'react';
import { passwordRules } from 'remote-user-portal/shared/utils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const ResetPasswordForm = forwardRef(({ account, submitted, onSubmit }, ref) => {
  const handleSubmit = useCallback(
    values => {
      onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Form ref={ref} onFinish={handleSubmit} style={{ width: '100%' }} {...formItemLayout}>
      <Form.Item name="password" label="Password" validateTrigger="onBlur" rules={passwordRules}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <div className="flex-row main-center" styleName="footer">
        <button
          className="primary"
          type="submit"
          data-test="change-password-btn"
          disabled={submitted}
        >
          Change Password
        </button>
      </div>
    </Form>
  );
});

ResetPasswordForm.displayName = 'ResetPasswordForm';
ResetPasswordForm.propTypes = {
  account: PropTypes.object,
  submitted: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ResetPasswordForm;
